import Vue from "vue";
import store from "../../store";
import Cookies from "js-cookie";
import JsEncrypt from "jsencrypt";
import { Loading } from "element-ui";
import { PUBLIC_KEY } from "./rsaEncrypt";
import { MessageBox, Message, Notification } from "element-ui";

let api;
const userKey = "USER";
const headerKey = "HEADER";
const uuid = require("uuid");
const momentObj = require("moment");
const dao = require("@/api/core/dao");
const record_loading_key = "record_loading";
const jseObj = new JsEncrypt();

/**
 * 创建 moment 对象, 操作日期/时间
 */
export function createMomentObject() {
    return momentObj;
}

/**
 * 获取随机UUID方法
 */
export function getUUID() {
    return uuid.v1().replace(/-/g, "");
}

/**
 * 时间格式化
 * @param {*} datetime
 * @param {*} format https://momentjs.cn/docs/#/displaying/format/
 * @returns
 */
export function timeFormat(datetime, format = "YYYY-MM-DD") {
    return momentObj(datetime).format(format);
}

/**
 * 时间加法
 * @param {*} datetime 指定时间
 * @param {*} value 添加天数/周数/月数/毫秒/小时
 * @param {*} type "days"/"weeks"/"months"/"milliseconds"/"hours"/...
 * @param {*} format YYYY-MM-DD/MM-DD/DD/...
 * @returns
 */
export function addTime(datetime, value, type, format = "YYYY-MM-DD") {
    return momentObj(datetime).add(value, type).format(format);
}

/**
 * 时间减法
 * @param {*} datetime 指定时间
 * @param {*} value 添加天数/周数/月数/毫秒/小时
 * @param {*} type "days"/"weeks"/"months"/"milliseconds"/"hours"/...
 * @param {*} format YYYY-MM-DD/MM-DD/DD/...
 * @returns
 */
export function subtractTime(datetime, value, type, format = "YYYY-MM-DD") {
    return momentObj(datetime).subtract(value, type).format(format);
}
/**
 * 获取指定年份，指定周，所有的日期
 */
export function getDatesOfWeek(year, weekNumber) {
    // 设置moment使用ISO周
    momentObj.locale("en", {
        week: {
            // 周一作为一周的第一天
            dow: 1,
            // 1月4号所在的周为一年中的第一周
            doy: 4
        }
    });
    // 计算指定周的第一天（周一）
    var startDate = momentObj().year(year).week(weekNumber).startOf("week");
    var dates = [];
    for (var i = 0; i < 7; i++) {
        // 添加当周的每一天到数组中
        dates.push(startDate.clone().add(i, "days").format("YYYY-MM-DD"));
    }
    return dates;
}
/**
 * 判断第一个时间是否在第二个之前
 * @param {*} time1
 * @param {*} time2
 * @returns
 */
export function timeIsBefore(time1, time2) {
    return momentObj(time1).isBefore(time2);
}

/**
 * 判断第一个时间是否在第二个之前或相同
 * @param {*} time1
 * @param {*} time2
 * @returns
 */
export function timeIsSameOrBefore(time1, time2) {
    return momentObj(time1).isSameOrBefore(time2);
}

/**
 * 秒转时间倒计时
 * @param {*} msd 秒
 * @returns
 */
export function timeToTxt(msd) {
    let time = msd;
    if (null != time && "" != time) {
        if (time > 60 && time < 60 * 60) {
            time = parseInt(time / 60.0) + "分钟" + parseInt((parseFloat(time / 60.0) - parseInt(time / 60.0)) * 60) + "秒";
        } else if (time >= 60 * 60 && time < 60 * 60 * 24) {
            time =
                parseInt(time / 3600.0) +
                "小时" +
                parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) +
                "分钟" +
                parseInt(
                    (parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
                        parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) *
                        60
                ) +
                "秒";
        } else if (time >= 60 * 60 * 24) {
            time =
                parseInt(time / 3600.0 / 24) +
                "天" +
                parseInt((parseFloat(time / 3600.0 / 24) - parseInt(time / 3600.0 / 24)) * 24) +
                "小时" +
                parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) +
                "分钟" +
                parseInt(
                    (parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
                        parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) *
                        60
                ) +
                "秒";
        } else {
            time = parseInt(time) + "秒";
        }
    }
    return time;
}

/**
 * 获取指定时间的周数
 * @param {*} datetime 指定时间
 * @returns
 */
export function getWeek(datetime) {
    return momentObj(datetime).format("w");
}

/**
 * 获取周数
 * @param {*} datetime 时间
 * @param {*} num 设定周数起算天，默认为周一
 * @returns
 */
export function getIsoWeek(datetime, num = 1) {
    momentObj.updateLocale("en", {
        week: {
            dow: num
        }
    });
    return momentObj(datetime).format("w");
}

/**
 * 获取传入月份的第一天
 */
export function getFirstOfMonth(datetime, format = "YYYY-MM-DD") {
    return momentObj(datetime).startOf("month").format(format);
}

/**
 * 获取传入月份的最后一天
 */
export function getLastOfMonth(datetime, format = "YYYY-MM-DD") {
    return momentObj(datetime).endOf("month").format(format);
}

/**
 * RSA 加密
 * @param {*} data 加密数据
 * @param {*} publicKey 公钥
 */
export function rsaEncrypt(data, publicKey) {
    if (!publicKey) {
        publicKey = PUBLIC_KEY;
    }
    jseObj.setPublicKey(publicKey);
    return jseObj.encrypt(data);
}

/**
 * RSA 解密
 * @param {*} data 解密数据
 * @param {*} publicKey 公钥
 * @param {*} privateKey 私钥
 */
export function rsaDecrypt(data, privateKey) {
    if (!privateKey) {
        return data;
    }
    jseObj.setPrivateKey(privateKey);
    return jseObj.decrypt(data);
}

/**
 * 执行组件方法
 * utils.exec("组件编号", "组件方法名", "方法入参", "可多个入参")
 */
export function exec(name, method, ...args) {
    api = store.getters.api;
    return api.exec(name, method, ...args);
}

/**
 * 获取记住密码时间
 */
export function getRememberTime() {
    let time = store.getters.config.iam.expire;
    if (!time || time === null) {
        time = "1y";
    }
    if (time.indexOf("y") > -1) {
        time = time.substr(0, time.length - 1);
        time = time * 31557600000;
    } else if (time.indexOf("d") > -1) {
        time = time.substr(0, time.length - 1);
        time = time * 86400000;
    } else if (time.indexOf("h") > -1) {
        time = time.substr(0, time.length - 1);
        time = time * 7200000;
    } else {
        time = 30 * 86400000;
    }
    return time;
}

/**
 * 表单校验
 * @param {*} field  不传校验所有字段, 传field 校验指导字段
 * @param {*} match  true 根据field 进行左匹配校验
 * @returns
 */
export function formValidate(field = "", match = false) {
    api = store.getters.api;
    let result = true;
    if (field == "") {
        api.validate((valid) => {
            result = valid;
        });
    } else if (!match) {
        api.validateField(field, (valid) => {
            if (valid) {
                result = false;
            }
        });
    } else {
        api.all()
            .filter((item) => item.field && item.field.indexOf(field) == 0)
            .forEach((item) => {
                api.validateField(item.field, (valid) => {
                    if (valid && isHaveNull(item.value)) {
                        result = false;
                    }
                });
            });
    }
    return result;
}

/**
 * 获取数据源的值
 */
export function getSourceData(source) {
    api = store.getters.api;
    if (source != undefined && source != "") {
        let sourceList = source.split(".");
        const fristSource = sourceList[0];
        let component = api.all().filter((item) => item.name === fristSource);
        if (component.length === 0) {
            console.log(fristSource + "对应的组件不存在或还在加载中");
        } else {
            const dataValue = api.exec(fristSource, "getValue");
            if (dataValue) {
                let result = dataValue;
                for (let i = 1; i < sourceList.length; i++) {
                    result = result[sourceList[i]];
                    if (result == undefined) {
                        break;
                    }
                }
                return result;
            }
        }
    }
}

/**
 * 更新数据源的值
 */
export function updateSourceData(source, data) {
    api = store.getters.api;
    if (source != undefined && source != "") {
        let sourceList = source.split(".");
        const fristSource = sourceList[0];
        let component = api.all().filter((item) => item.name === fristSource);
        if (component.length === 0) {
            console.log(fristSource + "对应的组件不存在或还在加载中");
            return;
        }
        const dataValue = api.exec(fristSource, "getValue");
        if (sourceList.length === 1) {
            api.exec(fristSource, "setValue", data);
            api.exec(fristSource, "noticeChange");
            return;
        }
        if (dataValue) {
            let result = dataValue;
            for (let i = 1; i < sourceList.length; i++) {
                if (i < sourceList.length - 1) {
                    if (result[sourceList[i]] == undefined) {
                        Vue.set(result, sourceList[i], {});
                    }
                    result = result[sourceList[i]];
                } else {
                    Vue.set(result, sourceList[i], data);
                }
            }
            result = data;
            api.exec(fristSource, "noticeChange");
        }
    }
}

/**
 * utils 获取组件的值
 * @export
 * @param {*} api
 * @param {*} componentNameUrl
 * @return {*}
 */
export function getValue(componentNameUrl) {
    api = store.getters.api;
    let urlList = componentNameUrl.split(".");
    let c = api.all().filter((item) => item.name === urlList[0] || item.field === urlList[0]);
    if (c.length > 0) {
        let conponent = c[0];
        let havaFind = true;
        for (let i = 1; i < urlList.length; i++) {
            let children = conponent.children.filter((item) => item.name === urlList[i] || item.field === urlList[0]);
            if (children.length > 0) {
                conponent = children[0];
            } else {
                havaFind = false;
                break;
            }
        }
        if (havaFind) {
            return conponent.__fc__.el.getValue();
        }
    }
    console.log("未找到组件");
    return undefined;
}

/**
 * 更新组件的值
 */
export function setValue(componentNameUrl, data, key) {
    api = store.getters.api;
    let urlList = componentNameUrl.split(".");
    let c = api.all().filter((item) => item.name === urlList[0] || item.field === urlList[0]);
    if (c.length > 0) {
        let conponent = c[0];
        let havaFind = true;
        for (let i = 1; i < urlList.length; i++) {
            let children = conponent.children.filter((item) => item.name === urlList[i] || item.field === urlList[0]);
            if (children.length > 0) {
                conponent = children[0];
            } else {
                havaFind = false;
                break;
            }
        }
        if (havaFind) {
            conponent.__fc__.el.setValue(data, key);
        }
    }
}

/**
 * 通知父级对象, 执行setValue
 */
export function noticeParentDataObject(formCreateInject, value) {
    let rule = formCreateInject.rule;
    let name = formCreateInject.field ? formCreateInject.field : formCreateInject.name;
    let dataObjectName;
    while (rule) {
        if (rule.type == "yes-data-object") {
            dataObjectName = rule.name;
            break;
        }
        if (!rule.__fc__.parent) {
            break;
        }
        rule = rule.__fc__.parent.rule;
    }
    if (dataObjectName) {
        formCreateInject.api.exec(dataObjectName, "setValue", value, name);
    }
}

/**
 * 打开新的窗口
 */
export function openNewWindow(url) {
    if (url == undefined) {
        window.open();
    } else {
        window.open(url);
    }
}

/**
 * 重刷页面
 */
export function reloadWindow(url) {
    if (url == undefined) {
        window.location.reload();
    } else {
        if (url === "/logout") removeUser();
        window.location.href = url;
    }
}

/**
 * 获取URL的请求参数
 */
export function getQueryParams() {
    var searchParams = new URLSearchParams(location.hash.substr(location.hash.indexOf("?")));
    var param = {};
    searchParams.forEach((v, k) => {
        param[k] = v;
    });
    return param;
}

/**
 * meassage 需要确认模型
 * @param {*} title
 * @param {*} conent
 * @param {*} thenFun
 * @param {*} catchFun
 * @param {*} confirmText
 * @param {*} cancelText
 */
export async function messageConfirm(title = "标题", conent = "内容", thenFun = async () => {}, catchFun = async () => {}, confirmText, cancelText) {
    let text = {
        confirmButtonText: confirmText ? confirmText : "确认",
        cancelButtonText: cancelText ? cancelText : "取消"
    };
    // Message使用参考element-ui里的弹窗里的对象
    await MessageBox.confirm(conent, title, text)
        .then(async () => {
            // Message使用参考element-ui里的消息提示
            await thenFun();
        })
        .catch(async (action) => {
            // Message使用参考element-ui里的消息提示
            await catchFun(Message, action);
        });
}

/**
 * message 只包含确认一个按钮
 * @param {*} title
 * @param {*} conent
 * @param {*} thenFun
 * @param {*} confirmText
 */
export async function messageAlert(title, conent, thenFun = async () => {}, confirmText) {
    // Message使用参考element-ui里的弹窗里的对象
    await MessageBox.alert(conent, title, {
        confirmButtonText: confirmText ? confirmText : "确定",
        callback: async (action) => {
            // Message使用参考element-ui里的消息提示
            await thenFun(Message, action);
        }
    });
}

/**
 * 成功弹窗
 */
export function messageSuccess(message, duration = 2000) {
    Message({
        dangerouslyUseHTMLString: true,
        message: message,
        type: "success",
        showClose: true,
        duration: duration
    });
}

/**
 * 错误弹窗
 */
export function messageError(message, duration = 2000) {
    Message({
        dangerouslyUseHTMLString: true,
        message: message,
        type: "error",
        showClose: true,
        duration: duration
    });
}

/**
 * 警告弹窗
 */
export function messageWarning(message, duration = 2000) {
    Message({
        dangerouslyUseHTMLString: true,
        message: message,
        type: "warning",
        showClose: true,
        duration: duration
    });
}

/**
 * 默认提示
 */
export function messageDefault(message, duration = 2000) {
    Message({
        dangerouslyUseHTMLString: true,
        message: message,
        showClose: true,
        duration: duration
    });
}

/**
 * 提示
 */
export function notify(title, message, position, duration = 2000, isHtml = false) {
    Notification.info({
        title: title,
        message: message,
        duration: duration,
        position: position ? position : "top-right",
        dangerouslyUseHTMLString: isHtml
    });
}

/**
 * 成功提示
 */
export function notifySuccess(title, message, position, duration = 2000, isHtml = false) {
    Notification({
        title: title,
        message: message,
        duration: duration,
        type: "success",
        position: position ? position : "top-right",
        dangerouslyUseHTMLString: isHtml
    });
}

/**
 * 错误提示
 */
export function notifyError(title, message, position, duration = 2000, isHtml = false) {
    Notification.error({
        title: title,
        message: message,
        duration: duration,
        position: position ? position : "top-right",
        dangerouslyUseHTMLString: isHtml
    });
}

/**
 * 警告提示
 */
export function notifyWarning(title, message, position, duration = 2000, isHtml = false) {
    Notification({
        title: title,
        message: message,
        duration: duration,
        type: "warning",
        position: position ? position : "top-right",
        dangerouslyUseHTMLString: isHtml
    });
}

export function getPageHash() {
    return window.location.hash;
}

// 用于存储每个页面路径的 Loading 实例和启动时间
let loadingInstances = {};
/**
 * 遮罩层加载动画
 * const loading = utils.loading();
 * loading.close();
 */
export function loading(message, background, log_model) {
    const pageHash = getPageHash();
    let path = pageHash ? pageHash : "/";
    // 如果已经存在加载实例，直接返回现有实例
    if (loadingInstances[path]) {
        return loadingInstances[path].instance;
    }
    let startTime = Date.now();
    const loading = Loading.service({
        lock: true,
        spinner: "el-icon-loading",
        text: message ? message : "处理中...",
        background: background ? background : "rgba(0, 0, 0, 0.5)"
    });
    loadingInstances[path] = {
        instance: loading,
        startTime: startTime
    };
    const record_loading = localStorage.getItem(record_loading_key);
    // 重写 close 方法
    let originalClose = loading.close;
    let uid = getUUID();
    loading.close = function (model) {
        let result_model = model || log_model ? log_model : "";
        console.info("close model", result_model);
        const elapsedTime = Date.now() - startTime;
        console.log(`Loading持续时间: ${elapsedTime} ms for page: ${path}`);
        // 调用原始的 close 方法
        originalClose.call(this);
        // 清除实例记录
        delete loadingInstances[path];
        // 如果开启后台记录, 则调用dao异步保存一条日志.
        if (record_loading && record_loading === "Y") {
            if (path && path.length > 100) {
                path = path.substring(0, 100);
            }
            const now_date_str = new Date().toISOString().slice(0, 19).replace("T", " ");
            setTimeout(() => {
                let dme_log_data = {
                    uid: uid,
                    req_time: elapsedTime,
                    modify_time: now_date_str
                };
                if (model) {
                    dme_log_data.model = model;
                }
                dao.modelPost("dme", "log", dme_log_data);
            }, 1000);
        }
    };
    if (record_loading && record_loading === "Y") {
        if (path && path.length > 100) {
            path = path.substring(0, 100);
        }
        dao.modelPost("dme", "log", {
            uid: uid,
            ip: "",
            path: path,
            device: "Other",
            app: "designer",
            model: log_model || "loading",
            req_time: 0,
            type: "designer/loading",
            params: JSON.stringify({ window_location_hash: pageHash })
        });
    }
    return loading;
}

export function loadingClose(model, key) {
    // console.info("loadingClose key", key);
    if (!key) {
        const pageHash = getPageHash();
        key = pageHash || "";
    }
    // console.info("loadingClose pageHash", key);
    if (!key) {
        return;
    }
    const instances = loadingInstances[key];
    if (!instances) {
        return;
    }
    instances.close(model);
    console.info("loadingClose");
}

/**
 * 获取请求头信息cookie
 */
export function getHeader() {
    // const token = Cookies.get(headerKey);
    // return token !== undefined ? JSON.parse(token) : undefined;
    const token = localStorage.getItem(headerKey);
    return token ? JSON.parse(token) : undefined;
}

/**
 * 设置请求头信息cookie
 */
export function setHeader(header) {
    // localStorage.setItem(headerKey, JSON.stringify(header));
    Cookies.set(headerKey, JSON.stringify(header));
    return localStorage.setItem(headerKey, JSON.stringify(header));
}

/**
 * 移除请求头信息cookie
 */
export function removeHeader() {
    // localStorage.removeItem(headerKey);
    Cookies.remove(headerKey);
    return localStorage.removeItem(headerKey);
}

/**
 * 获取权限信息cookie
 */
export function getPermissions(key) {
    // const permissions = Cookies.get("permissions");
    const permissions = localStorage.getItem("permissions");
    let p = permissions ? JSON.parse(permissions) : undefined;
    if (p && key) {
        let i = p.filter((item) => {
            return item === key;
        });
        return i.length > 0;
    }
    return false;
}

/**
 * 获取用户信息cookie
 */
export function getRememberUser() {
    // const remember_account = Cookies.get("remember_account");
    // const remember_token = Cookies.get("remember_token");
    // let account = remember_account !== undefined ? remember_account : undefined;
    // let token = remember_token !== undefined ? remember_token : undefined;
    // if (!account) {
    //     account = localStorage.getItem("remember_account");
    // }
    // if (!token) {
    //     token = localStorage.getItem("remember_token");
    // }
    let account = localStorage.getItem("remember_account");
    let token = localStorage.getItem("remember_token");
    let result = {
        account: account,
        token: token
    };
    return account != undefined ? result : undefined;
}

/**
 * 缓存用户信息cookie
 */
export function rememberUser(account, token) {
    // let expires = new Date(new Date() * (1 + getRememberTime()));
    // Cookies.set("remember_account", account, { expires: expires });
    // Cookies.set("remember_token", token, { expires: expires });
    localStorage.setItem("remember_account", account);
    localStorage.setItem("remember_token", token);
}

/**
 * 移除用户信息cookie
 */
export function removeUser() {
    // Cookies.remove("remember_token");
    // Cookies.remove("remember_account");
    localStorage.removeItem("remember_token");
    localStorage.removeItem("remember_account");
}

/**
 * 从config中读取首页地址
 */
export function getHomePage() {
    return store.getters.page.home;
}

/**
 * 获取当前行的值,仅作为插槽放到表格列里可使用
 */
export function getRow(vm) {
    let parent = vm.$parent;
    while (!parent.row) {
        if (!parent.$parent) break;
        parent = parent.$parent;
    }
    return parent.row;
}

/**
 * 获取当前行的索引,仅作为插槽放到表格列里可使用
 */
export function getIndex(vm) {
    if (vm.fDataSource) {
        let list = vm.fDataSource.split(".");
        let index = null;
        list.map((v) => {
            if (!isNaN(Number(v, 10))) index = v;
        });
        return index;
    }
    let parent = vm.$parent;
    while (!parent.row) {
        if (!parent.$parent) break;
        parent = parent.$parent;
    }
    return parent.index;
}

/**
 * 获取当前行的索引,在数据循环列表中使用
 */
export function getArrayIndex(vm) {
    let result = vm._props.formCreateInject.rule.props.fIndex;
    if (result == undefined) {
        let parents = vm.$parent;
        result = parents._props.formCreateInject.rule.props.fIndex;
        while (result == undefined) {
            if (!parents.$parent) break;
            parents = parents.$parent;
            result = parents._props.formCreateInject.rule.props.fIndex;
        }
    }
    return result;
}

/**
 * 根据编号或者字段ID插入规则
 */
export function addRule(name, rule) {
    api = store.getters.api;
    let component = api.all().find((item) => (item.name && item.name === name) || (item.field && item.field === name));
    component.children.push(rule);
}

/**
 * 更新子级规则
 */
export function updateChilerenRule(name, rule) {
    api = store.getters.api;
    api.updateRule(name, { children: rule });
}

/**
 * 根据编号或者字段ID获取组件的方法
 */
export function getRule(name) {
    api = store.getters.api;
    let component = api.all().find((item) => (item.name && item.name === name) || (item.field && item.field === name));
    return component;
}

/**
 * 存储用户信息到vuex
 */
export function setUser(user) {
    localStorage.setItem(userKey, JSON.stringify(user));
}

/**
 * 从vuex里取出用户信息
 */
export function getUser() {
    const userStorage = localStorage.getItem(userKey);
    return userStorage ? JSON.parse(userStorage) : {};
}

/**
 * 查询序列号流水号
 */
export async function getSerial(app, code, header) {
    const data = {
        app: app, //pdm
        code: code, //category_co1
        header: header //c01M01
    };
    // 第一次查serial表是否存在app和code
    let res = await dao.view("dme", "serial", {
        app: app,
        code: code
    });
    if (res.success && res.data.length > 0) {
        // 第二次查是否存在当前header 有返回流水号
        let array = [];
        array.push({
            uid: res.data[0].uid,
            number: res.data[0].number,
            length: res.data[0].length
        });
        let sres = await dao.view("dme", "serial", data);
        // 如果没数据返回false
        return sres.data.length > 0 ? array : null;
    }
    return false;
}

/**
 * 判断字段是否包含空格
 */
export function isHaveNull(str) {
    if (str === "" || str === null || str === undefined) return true;
    var regu = "^[ ]+$";
    var re = new RegExp(regu);
    return re.test(str);
}

/**
 * 判断是否包含汉字
 */
export function isChinese(s) {
    var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    if (reg.test(s)) {
        return true;
    }
    return false;
}

/**
 * 字符串包含英文
 */
export function isEnglish(s) {
    var reg = new RegExp("[a-zA-Z]+", "g");
    if (reg.test(s)) {
        return true;
    }
    return false;
}

/**
 * 身份证号验证
 */
export function isIdCard(cardid) {
    // 身份证正则表达式(18位)
    var isIdCard2 = /^[1-9]\d{5}(19\d{2}|[2-9]\d{3})((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])(\d{4}|\d{3}X)$/i;
    var stard = "10X98765432"; //最后一位身份证的号码
    var first = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; //1-17系数
    var sum = 0;

    if (!isIdCard2.test(cardid)) {
        return false;
    }

    var year = cardid.substr(6, 4);
    var month = cardid.substr(10, 2);
    var day = cardid.substr(12, 2);
    var birthday = cardid.substr(6, 8);

    if (birthday != dateToString(new Date(year + "/" + month + "/" + day))) {
        // 校验日期是否合法
        return false;
    }

    for (var i = 0; i < cardid.length - 1; i++) {
        sum += cardid[i] * first[i];
    }

    var result = sum % 11;
    // 计算出来的最后一位身份证号码
    var last = stard[result];
    if (cardid[cardid.length - 1].toUpperCase() == last) {
        return true;
    }
    return false;
}

/**
 * 日期转字符串 返回日期格式20080808
 */
export function dateToString(date) {
    if (date instanceof Date) {
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        var day = date.getDate();
        day = day < 10 ? "0" + day : day;
        return year + month + day;
    }
    return "";
}

/**
 * 格式化日期
 */
export function formatDate(value = Date.now(), format = "Y-M-D") {
    const formatNumber = (n) => `0${n}`.slice(-2);
    const date = new Date(value);
    const formatList = ["Y", "M", "D", "h", "m", "s"];
    const resultList = [];
    resultList.push(date.getFullYear().toString());
    resultList.push(formatNumber(date.getMonth() + 1));
    resultList.push(formatNumber(date.getDate()));
    resultList.push(formatNumber(date.getHours()));
    resultList.push(formatNumber(date.getMinutes()));
    resultList.push(formatNumber(date.getSeconds()));
    for (let i = 0; i < resultList.length; i++) {
        format = format.replace(formatList[i], resultList[i]);
    }
    return format;
}

/**
 * 格式化日期时间
 */
export function formatTime(value = Date.now(), format = "Y-M-D h:m:s") {
    const formatNumber = (n) => `0${n}`.slice(-2);
    const date = new Date(value);
    const formatList = ["Y", "M", "D", "h", "m", "s"];
    const resultList = [];
    resultList.push(date.getFullYear().toString());
    resultList.push(formatNumber(date.getMonth() + 1));
    resultList.push(formatNumber(date.getDate()));
    resultList.push(formatNumber(date.getHours()));
    resultList.push(formatNumber(date.getMinutes()));
    resultList.push(formatNumber(date.getSeconds()));
    for (let i = 0; i < resultList.length; i++) {
        format = format.replace(formatList[i], resultList[i]);
    }
    return format;
}

/**
 * 获取月份中的天数
 */
export function formatDays(value = Date.now()) {
    var date = new Date(value);
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);
    let month = date.getMonth() + 1;
    let days = [];
    for (let i = 1; i <= date.getDate(); i++) {
        days.push(month + "-" + i);
    }
    return days;
}

/**
 * 获取2个月之间的月份
 */
export function formatMonth(value = Date.now(), value1 = Date.now()) {
    var date = new Date(value);
    let month = date.getMonth() + 1;
    var date1 = new Date(value1);
    let month1 = date1.getMonth() + 1;
    let days = [];
    for (let i = month; i <= month1; i++) {
        days.push(i);
    }
    return days;
}

/**
 * 是否是手机号
 */
export function isPhone(obj) {
    // 正则判断
    var pattern = /^1[3456789]\d{9}$/;
    if (!pattern.test(obj)) {
        return false;
    }
    return true;
}

/**
 * 根据Value获取Key
 */
export function findKeyByValue(obj, value) {
    for (let key in obj) {
        if (obj[key] === value) {
            return key;
        }
    }
    return null;
}

/**
 * 将数字转大写
 */
export function transCnMoney(number) {
    var CN_MONEY = "";
    var CN_UNIT = "仟佰拾亿仟佰拾万仟佰拾元角分";
    // 浏览器默认为整数型,将数字转为2位有效数字的float类型再转为字符串
    number = parseFloat(number).toFixed(2).toString();
    // 将从小数点开始分开
    var dot = number.indexOf(".");
    if (dot >= 0) {
        number = number.substring(0, dot) + number.substr(dot + 1, 2);
        CN_UNIT = CN_UNIT.substr(CN_UNIT.length - number.length);
        for (var i = 0; i < number.length; i++) {
            CN_MONEY += "零壹贰叁肆伍陆柒捌玖".substr(number.substr(i, 1), 1) + CN_UNIT.substr(i, 1);
        }
        return CN_MONEY.replace(/零角零分$/, "整")
            .replace(/零[仟佰拾]/g, "零")
            .replace(/零{2,}/g, "零")
            .replace(/零([亿|万])/g, "$1")
            .replace(/零+元/, "元")
            .replace(/亿零{0,3}万/, "亿")
            .replace(/^元/, "零元");
    }
    return undefined;
}

/**
 * 搜索页面div的隐藏/禁用脚本, 执行并设置是否隐藏/禁用
 */
export function refresh(vm) {
    const that = this;
    api = store.getters.api;
    // 找出有写隐藏脚本的div
    let hiddenComponent = api.all().filter((item) => item.props.hidderScript && item.props.hidderScript != "");
    hiddenComponent.map(async (v) => {
        const scirptFuc = window.AsyncFunction("vm", "api", "dao", "utils", v.props.hidderScript);
        const result = await scirptFuc(v, api, dao, that);
        if (result) {
            v.__fc__.el && v.__fc__.el.hidden();
        } else {
            v.__fc__.el && v.__fc__.el.show();
        }
    });
    // 找出禁用脚本的div
    let disabledComponent = api.all().filter((item) => item.props.disabledScript && item.props.disabledScript != "");
    disabledComponent.map(async (v) => {
        const scirptFuc = window.AsyncFunction("vm", "api", "dao", "utils", v.props.disabledScript);
        const result = await scirptFuc(vm, api, dao, that);
        if (result) {
            that.setChildrenToDisabled(v.children, true);
        } else {
            that.setChildrenToDisabled(v.children, false);
        }
    });
}

/**
 * 搜索子级, 执行是否隐藏/禁用
 */
export function setChildrenToDisabled(list, disabled) {
    const that = this;
    list.map((v) => {
        if (v.__fc__.el && v.__fc__.el.setDisabled) {
            v.__fc__.el.setDisabled(disabled);
        }
        if (v.children && v.children.length > 0) {
            that.setChildrenToDisabled(v.children, disabled);
        }
    });
}

/**
 * 精确加法运算
 */
export function plus(num1, num2) {
    num1 = Number(num1);
    num2 = Number(num2);
    const num1Digits = (num1.toString().split(".")[1] || "").length;
    const num2Digits = (num2.toString().split(".")[1] || "").length;
    const baseNum = Math.pow(10, Math.max(num1Digits, num2Digits));
    return (this.times(num1, baseNum) + this.times(num2, baseNum)) / baseNum;
}

/**
 * 精确减法运算
 */
export function minus(num1, num2) {
    num1 = Number(num1);
    num2 = Number(num2);
    const num1Digits = (num1.toString().split(".")[1] || "").length;
    const num2Digits = (num2.toString().split(".")[1] || "").length;
    const baseNum = Math.pow(10, Math.max(num1Digits, num2Digits));
    return (this.times(num1, baseNum) - this.times(num2, baseNum)) / baseNum;
}

/**
 * 精确乘法运算
 */
export function times(num1, num2) {
    num1 = Number(num1);
    num2 = Number(num2);
    const num1String = num1.toString();
    const num2String = num2.toString();
    const num1Digits = (num1String.split(".")[1] || "").length;
    const num2Digits = (num2String.split(".")[1] || "").length;
    const baseNum = Math.pow(10, num1Digits + num2Digits);
    return (Number(num1String.replace(".", "")) * Number(num2String.replace(".", ""))) / baseNum;
}

/**
 * 精确除法运算
 */
export function divide(num1, num2) {
    num1 = Number(num1);
    num2 = Number(num2);
    const num1String = num1.toString();
    const num2String = num2.toString();
    const num1Digits = (num1String.split(".")[1] || "").length;
    const num2Digits = (num2String.split(".")[1] || "").length;
    const baseNum = Math.pow(10, num1Digits + num2Digits);
    let n1 = this.times(num1, baseNum);
    let n2 = this.times(num2, baseNum);
    return Number(n1) / Number(n2);
}

/**
 * 判断字段是否包含空格
 */
export function isNull(ob) {
    // 校验 undefined, null, 空字符串
    if (ob == null || ob === "") return true;
    // 校验空对象
    if (typeof ob === "object" && JSON.stringify(ob) === "{}") return true;
    // 校验空数组
    if (Array.isArray(ob) && ob.length === 0) return true;
    return false;
}

/**
 * 获取下一个周一日期
 */
export function getNextMonday(dateString) {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    const daysUntilNextMonday = dayOfWeek === 1 ? 7 : (8 - dayOfWeek) % 7;
    const nextMonday = new Date(date.getTime() + daysUntilNextMonday * 24 * 60 * 60 * 1000);
    nextMonday.setHours(0);
    nextMonday.setMinutes(0);
    nextMonday.setSeconds(0);
    return nextMonday;
}

/**
 * 获取下一个周日日期
 */
export function getNextSunday(dateString) {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    const daysUntilNextSunday = dayOfWeek === 0 ? 7 : 7 - dayOfWeek;
    const nextSunday = new Date(date.getTime() + (daysUntilNextSunday + 7) * 24 * 60 * 60 * 1000);
    nextSunday.setHours(23);
    nextSunday.setMinutes(59);
    nextSunday.setSeconds(59);
    return nextSunday;
}

/**
 * 获取周日日期
 */
export function getSunday(date) {
    // 获取当前日期的星期几（0-6，其中0表示星期日）
    date = new Date(date);
    var day = date.getDay();
    // 计算当前日期与周日之间的天数差值
    var diff = date.getDate() - day + (day === 0 ? 0 : 7);
    // 创建一个新的日期对象，该对象为当前日期的周日
    var sunday = new Date(date.setDate(diff));
    sunday.setHours(23);
    sunday.setMinutes(59);
    sunday.setSeconds(59);
    return sunday;
}

/**
 * 获取周一日期
 */
export function getMonday(date) {
    // 获取当前日期的星期几（0-6，其中0表示星期日）
    date = new Date(date);
    var day = date.getDay();
    // 计算当前日期与周一之间的天数差值
    var diff = date.getDate() - day + (day === 0 ? -6 : 1);
    // 创建一个新的日期对象，该对象为当前日期的周一
    var monday = new Date(date.setDate(diff));
    monday.setHours(0);
    monday.setMinutes(0);
    monday.setSeconds(0);
    return monday;
}

/**
 * 1. 给字符串形式的日期加上指定小时数
 * 2. 转换为日期对象
 */
export function addHoursToDate(dateString, hours) {
    const startDate = new Date(dateString);
    // 加上指定小时数
    const hoursQty = hours * 60 * 60 * 1000;
    const updatedDate = new Date(startDate.getTime() + hoursQty);
    return updatedDate.toISOString();
}
