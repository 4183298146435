import request from '@/utils/request'

export function saveComponent(data) {
  return request({
    url: '/app/fde/saveFdeComponent',
    method: 'post',
    data: data
  })
}

export function getComponent(data) {
  return request({
    url: '/app/fde/getFdeComponent',
    method: 'post',
    data: data
  })
}

export function getFdeParams(data) {
  return request({
    url: '/app/fde/getFdeParams',
    method: 'post',
    data: data
  })
}
